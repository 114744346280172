import React, { useEffect, useState } from "react";
import axios from "axios";
import LoginBtn from "./LoginBtn";
import moment from "moment";
import Chrono from "./Chrono";
import { DateTime } from "luxon";

function ItemList() {
  console.log("In item list...");

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/items`)
      .then((res) => {
        setItems(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        window.location.href = "/login";
      });
  }, []);

  if (isLoading || !items) {
    return <span>Loading...</span>;
  }

  return (
    <div id="primary" className="no-padding content-area no-sidebar">

      <div style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}>
        <LoginBtn />
      </div>

      <div className="container">
        <main id="main" className="col-md_12 site-main main-content">
          <article
            id="post-6078"
            className="post-6078 page type-page status-publish hentry"
          >
            <div className="entry-content">
              <div
                data-vc-full-width="true"
                data-vc-full-width-init="false"
                data-vc-stretch-content="true"
                className="vc_row wpb_row vc_row-fluid box-sh vc_custom_1584635708946"
              >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="iconfilter-shortcode portfolio-posts-list portfolio-posts-list2 wow ">
                        <div className="row">
                          <main
                            style={{ minHeight: 0 }}
                            className="cd-main-content"
                          >
                            <section className="cd-gallery">
                              <a
                                target="_blank"
                                href="https://www.bromont.net/services-aux-citoyens/fondation-du-maire/"
                                className="mb-12"
                              >
                                <img
                                  style={{
                                    width: 285,
                                    margin: "30px auto 50px auto"
                                  }}
                                  alt="Logo"
                                  src="../../bienvenue.png"
                                />
                              </a>

                              {items.length > 0 ? (
                                <div className="alert alert-info fs-3 text-center" role="alert">
                                  {moment(items[0].start_date) < moment() ? (
                                    <Chrono endDate={DateTime.fromISO(items[0].end_date)}
                                            onEnd={() => console.log("ended")} />
                                  ) : (
                                    <>
                                      Les enchères débuteront le{" "}
                                      {moment(items[0].start_date).format("DD/MM/YYYY")}{" "}
                                    </>
                                  )}
                                </div>
                              ) : (<span>Non spécifié</span>)}


                              <ul id="MixItUp4A0723">
                                {items.map((item) => {
                                  return (
                                    <li
                                      key={item.id}
                                      className="mix erin-morgenstern ebook before-1900  anthology Pink Floyd - The Dark Side Moon languages"
                                      style={{ display: "inline-block", height: 460 }}
                                    >
                                      <div className="col-md-12 post ">
                                        <div className="product-wrapper">
                                          <div className="thumbnail-and-details" style={{ height: "100%" }}>
                                            <a
                                              className="woo_catalog_media_images"
                                              title="Voir les détails de l'objet"
                                              href={`item/${item.id}`}
                                              style={{ display: "flex", alignItems: "center", height: "100%" }}
                                            >
                                              <img
                                                style={{ padding: 10 }}
                                                className="portfolio_post_image"
                                                src={`../../prix/${item.img_url}`}
                                                alt={item.name}
                                              />
                                            </a>
                                          </div>
                                          <div
                                            style={{
                                              height: 150,
                                              paddingBottom: 0,
                                              paddingTop: 0
                                            }}
                                            className="woocommerce-title-metas"
                                          >
                                            <h3 className="archive-product-title">
                                              <a
                                                href={`item/${item.id}`}
                                                title={item.name}
                                                style={{ fontSize: item.name.length > 20 ? 12 : 14 }}
                                              >
                                                {item.name}
                                              </a>
                                            </h3>

                                            <div style={{ textAlign: "left" }}>
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  textAlign: "left",
                                                  whiteSpace: "nowrap"
                                                }}
                                              >
                                                Par:{" "}
                                                <span className="woocommerce-Price-amount amount">
                                                  {item.sponsor}
                                                </span>
                                              </p>
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  textAlign: "left"
                                                }}
                                              >
                                                Valeur:{" "}
                                                <span className="woocommerce-Price-amount amount">
                                                  <bdi>
                                                    {item.worth}
                                                    <span className="woocommerce-Price-currencySymbol">
                                                      $
                                                    </span>
                                                  </bdi>
                                                </span>
                                              </p>
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  textAlign: "left"
                                                }}
                                              >
                                                {item.current_price ? ("Enchère actuelle: ") : ("Prix de départ: ")}
                                                <span className="woocommerce-Price-amount amount">
                                                    <bdi>
                                                      {item.current_price ? item.current_price.toFixed(0) : item.start_price.toFixed(0)}
                                                      <span className="woocommerce-Price-currencySymbol">
                                                        $
                                                      </span>
                                                    </bdi>
                                                  </span>
                                              </p>

                                              {item.current_price && (
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    textAlign: "left"
                                                  }}
                                                >
                                                  En tête:{" "}
                                                  <span className="woocommerce-Price-amount amount">
                                                    <bdi>
                                                      {item.current_user}
                                                    </bdi>
                                                  </span>
                                                </p>
                                              )}

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </section>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  );
}

export default ItemList;
