import React, { useState } from "react";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsredirect] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`api/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email,
          password,
          phone_number: phoneNumber,
          name
        })
      });

      if (response.status === 200) {
        window.location.href = "/";
        setIsredirect(true);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  if (isRedirect) {
    return null;
  }

  return (
    <div id="kt_body" className="bg-body">
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a target="_blank" href="https://www.bromont.net/services-aux-citoyens/fondation-du-maire/"
               className="mb-12">
              <img
                alt="Logo"
                style={{ width: 500, margin: "50px 0" }}
                src="../../bienvenue.png"
              />
            </a>

            <div style={{ width: 450 }} className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_up_form"
              >
                <div className="mb-10 text-center">
                  <h1 className="text-dark mb-3">Créer un compte</h1>

                  <div className="text-gray-400 fw-bold fs-4">
                    Vous avez un compte ? {"  "}
                    <a
                      href="/login"
                      className="link-primary fw-bolder"
                    >
                      Connexion
                    </a>
                  </div>
                </div>

                <div className="fv-row mb-7">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Nom
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                </div>

                <div className="fv-row mb-7">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Email
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="email"
                    placeholder=""
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="fv-row mb-7">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Numéro de téléphone
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    name="first-name"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />

                </div>

                <div className="mb-10 fv-row" data-kt-password-meter="true">
                  <div className="mb-1">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mot de passe
                    </label>

                    <div className="position-relative mb-3">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        placeholder=""
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>


                  </div>
                </div>

                <div className="fv-row mb-5">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Confirmer mot de passe
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    placeholder=""
                    name="confirm-password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </div>

                <div className="text-center">
                  <button
                    id="kt_sign_up_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={
                      isLoading || !password || !email || !phoneNumber || !name || (password !== password2)
                    }
                    onClick={onSubmit}
                    type="submit"
                  >
                    <span className="indicator-label">Créer un compte</span>
                  </button>
                </div>

                <div className="text-center">
                  <a href="/" className="link-primary">Retour à l'accueil</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  /*  return (
    <main className="container">
      <article className="grid">
        <div>
          <hgroup>
            <h1>Sign up</h1>
          </hgroup>
          <form>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              type="text"
              name="phone_number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
            />

            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button
              disabled={
                isLoading || !password || !email || !phoneNumber || !name
              }
              onClick={onSubmit}
              type="submit"
              className="contrast"
            >
              Register
            </button>
          </form>
        </div>
        <div></div>
      </article>
    </main>
  );*/
}

export default Register;
