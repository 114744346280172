import React, { useState } from "react";

import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const onSubmit = async () => {
    try {
      setError(false);
      setIsLoading(true);
      const response = await fetch(`api/login/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: email,
          password
        })
      });

      if (response.status === 200) window.location.href = "/";
      else setError(true);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="kt_body" className="bg-body">
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a target="_blank" href="https://www.bromont.net/services-aux-citoyens/fondation-du-maire/"
               className="mb-12">
              <img
                alt="Logo"
                style={{ width: 500, margin: "50px 0" }}
                src="../../bienvenue.png"
              />
            </a>
            <div style={{ width: 450 }} className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_in_form"
              >
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3">Connexion</h1>

                  <div className="text-gray-400 fw-bold fs-4">
                    Nouveau?{"  "}
                    <a
                      href="/register"
                      className="link-primary fw-bolder"
                    >
                      Créer un compte
                    </a>
                  </div>
                </div>
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Email
                  </label>

                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") onSubmit().then();
                    }}
                  />
                </div>
                <div className="fv-row mb-10">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      Mot de Passe
                    </label>
                  </div>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") onSubmit().then();
                    }}
                  />
                </div>
                <div className="text-center">
                  {error && (
                    <div className="text-danger fw-bold fs-6 mb-3">
                      <div className="mb-2">Email ou mot de passe incorrect.</div>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    disabled={isLoading || !password || !email}
                    onClick={onSubmit}
                    type="button"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                  >
                    <span className="indicator-label">Connexion</span>
                  </button>
                </div>

                <div className="text-center">
                  <a href="/" className="link-primary">Retour à l'accueil</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
